@import 'src/mixins';

.root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
  transition: .3s;

  &.active {
    z-index: 100;
    opacity: 1;
  }

  &.global {
    background-color: var(--white);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    .circle {
      width: 80px;
      height: 80px;
    }
  }

  .circle {
    @include circle()
  }

}
