/*<==========================CSS reset==========================>*/
*, *::before, *::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

/*html, body {*/
/*    height: 100%;*/
/*}*/

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6, input {
    overflow-wrap: break-word;
    font-family: 'Open Sans', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
}

li {
    list-style: none;
}

#root, #__next {
    isolation: isolate;
}

#root {
    min-height: 100vh;
    height: 100%;
}

/*<=====================Global class for modal========================>*/

.MuiInputBase-formControl {
    height: 54px
}

.header-with-title-wrapper {
    transform: translateY(-40px);
}

@media (min-width: 1024px) {
    .header-with-title-wrapper {
        transform: translateY(-64px);
    }
}

.css-1km1ehz {
    font-size: 16px !important;
}

.header-with-bar-wrapper {
    transform: translateY(-40px);
}

.c-long-form-container {
    min-width: min(60vw, 800px);
}

.MuiFilledInput-input {
    font-size: 12px !important;
}

@media (min-width: 680px) {
    .header-with-bar-wrapper {
        transform: translateY(-64px);
    }
}

@media(min-width: 400px) {
    .MuiFilledInput-input {
        font-size: 16px !important;
    }
}

/*<==========================Main colors==========================>*/

:root {
    --blue: #3366FF;
    --darkBlue: #000033;
    --black: #000000;
    --lightGray: #F2F2F2;
    --white: #FFFFFF;
    --red: #FF0000;
    --yellow: #FFFF00;
    --green: #00CC00;
    --textPrimary: #003;
}

/*<==========================Main spacing==========================>*/
:root {
    --wrapperPadding: 16px;
    --headerHeight: 84px;
    --footerHeight: 184px;
}

/*<==========================Size==========================>*/
:root {
    --containerSize: 1024px;
}

/*<==========================Global css==========================>*/
.errorText {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--red)
}

html {
    font-size: 4px;
}

@media (min-width: 1080px) {
    html {
        font-size: 10px;
    }
}

@media (min-width: 680px) {
    :root {
        --wrapperPadding: 12rem;
        --headerHeight: 70px;
    }
}

/*<==========================Common css==========================>*/
