@mixin faq {
  padding: 64px 0;
  position: relative;
  @include bg(var(--darkBlue))
}

@mixin bg($color) {
  isolation: isolate;

  &::before {
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-color: $color;
  }
}

@mixin circle {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--black);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 8px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;


    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
